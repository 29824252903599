import axios from "axios";
import { getCookie, checkCookies } from "cookies-next";

export const getSuggestedAndStayData = async (
  langCode: string,
  section: string
): Promise<SuggestedAndStaysType[]> => {
  const currency = checkCookies("currency_code")
    ? getCookie("currency_code")
    : "USD";

  try {
    const response = await axios.post(
      `/api/suggestedDestinationAndStayAround/getApiData`,
      {
        langCode,
        currency,
        section,
      }
    );
    return response?.data?.homepageinfo;
  } catch (error) {
    console.info(error);
    return [];
  }
};
