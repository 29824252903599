import { CardViewer } from "@components/CardViewer";
// import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import {
  MainBody,
  MainH2,
  MainP,
  SkeletonCol,
  Spacer,
  // BtnArrow,
  // Arrow,
  CustomCarousel,
  SkeletonMainBody,
} from "./styles";
import { useBreakpoints } from "@hooks/useBreakpoints";
import { getSuggestedAndStayData } from "@lib/api/home/suggestedDestinationAndStayAround";
import moment from "moment";
import { getDateRange } from "@helpers/helper";
import { SkeletonCard } from "@components/Cards/Skeleton";
import {
  sanitizCarData,
  sanitizeFlightData,
  sanitizeHotelData,
} from "@helpers/helperRecentSearches";
import { getRecentSearchByPage } from "@lib/api/home/getRecentSearchByPage";
import { useAuth } from "@contexts/AuthContext";
import { useMain } from "@contexts/mainContext";
import { useWindowSize } from "@hooks/useWindowSize";
import { useLayoutStore } from "@store/layoutStore";
import { shallow } from "zustand/shallow";

type ResentSearchesType = {
  type: string;
  title: string;
  subtitle: string;
  section?: string;
};

export const RecentSearches = ({
  title,
  subtitle,
  type,
  section = "hotel",
}: ResentSearchesType) => {
  const { breakpoints } = useBreakpoints();
  const size = useWindowSize();
  const [sanitizedData, setSanitizedData] = useState<MainCardType[]>([]);
  const [disabled, setdisabled] = useState(false);
  const [isTriggeredApi, setIsTriggeredApi] = useState(true);
  const [isloading, setIsloading] = useState(true);
  const { isLogged } = useAuth();
  const { dateFormat } = useMain();
  const { showRecentSearchesMobile } = useLayoutStore(
    (state) => ({
      showRecentSearchesMobile: state.externalConfig?.showRecentSearchesMobile,
    }),
    shallow
  );

  const cardBreakpoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 1 },
    { width: 850, itemsToShow: 3, itemsToScroll: 1 },
    { width: 1150, itemsToShow: 4, itemsToScroll: 1 },
    { width: 1450, itemsToShow: 4, itemsToScroll: 1 },
    { width: 1750, itemsToShow: 4, itemsToScroll: 1 },
  ];

  const sanitizeDataCard = (data: DestinationInfoType[]) => {
    var temp: MainCardType[] = [];
    //console.log("recent searches", data);
    var startDate = moment().add(15, "days").calendar();
    var endDate = moment().add(16, "days").calendar();
    data?.map((val) => {
      if (val !== null) {
        let urlBuilder = {
          dest_type: "other",
          dateRange: getDateRange(moment(startDate), moment(endDate)), //"Sun, Aug 28 22 - Wed, Aug 31 22",
          destination_id: String(val?.destination_id),
          latitude: val?.latitude,
          location: `${val?.city_name}, ${val?.country_code}`,
          longitude: val?.longitude,
          paxConfig: '[{"room":1,"adult":2,"children":0,"child":[]}]',
          search_type: "1",
        };
        let queryString = new URLSearchParams(urlBuilder).toString();

        return temp.push({
          type: "hotel",
          image: [val?.thumbnail || "undefined"],
          themes: val?.themes,
          title: `${val?.city_name}, ${val?.country_code}`,
          subtitle: val?.hotel_name,
          b2b: String(val?.price_per_night),
          b2c: "0",
          currency: val?.currency,
          priceConditions: "per night",
          score: val?.customer_rating,
          scoreLabel: "",
          stars: Number(val?.star_rating),
          subtitle2: "",
          price: 0,
          url: { en: queryString },
        });
      }
    });

    console.log("temp sanitized", temp);

    setSanitizedData(temp);
  };

  // const myArrow = ({ type, onClick, isEdge }: any) => {
  //   return (
  //     <BtnArrow
  //       onClick={onClick}
  //       disabled={isEdge}
  //       theme={{
  //         isRight: !(type === "PREV"),
  //         isEdge: isEdge && !breakpoints.down("md"),
  //         isMobilePage: breakpoints.down("md") ? true : false,
  //       }}
  //       variant="light"
  //     >
  //       {type === "PREV" ? <Arrow /> : <Arrow theme={{ isRight: true }} />}
  //     </BtnArrow>
  //   );
  // };

  const orderObjectByDate = (data: MainCardType[], orderBy = "asc") => {
    data.sort(function (a, b) {
      if (a?.creation_time && b?.creation_time) {
        var keyA = new Date(a?.creation_time),
          keyB = new Date(b?.creation_time);
        // Compare the 2 dates
        if (orderBy == "asc") {
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
        } else {
          if (keyA > keyB) return -1;
          if (keyA < keyB) return 1;
        }
      }

      return 0;
    });
  };

  const isSameDay = (d1: string, d2: string) => {
    const d1HasT = d1.includes("T");
    const d2HasT = d2.includes("T");
    let date1 = d1;
    let date2 = d2;

    if (d1HasT) {
      date1 = d1.split("T")[0];
    }
    if (d2HasT) {
      date2 = d2.split("T")[0];
    }

    return new Date(date1).getTime() === new Date(date2).getTime();
  };

  const filterRepeatItems = (data: MainCardType[], type: string) => {
    return data.reduce((acc: MainCardType[], current: MainCardType) => {
      const x = acc.find((item: MainCardType) => {
        if (type === "hotel") {
          return (
            item.title === current.title &&
            item.subtitle === current.subtitle &&
            isSameDay(
              String(item?.creation_time),
              String(current.creation_time)
            )
          );
        }

        if (type === "car") {
          return (
            item.title === current.title &&
            isSameDay(
              String(item?.creation_time),
              String(current.creation_time)
            )
          );
        }
        if (type === "flight") {
          return (
            item.title === current.title &&
            item.dateDeparture === current.dateDeparture &&
            item.subtitle === current.subtitle &&
            item.dateReturn === current.dateReturn &&
            isSameDay(
              String(item?.creation_time),
              String(current.creation_time)
            )
          );
        }
      });
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
  };

  const getDataApi = useCallback(async (langCode = "en-US") => {
    if (section === "all") {
      let temp = sanitizedData;
      const response1 = await getRecentSearchByPage("ALL");
      setIsTriggeredApi(false);
      const dataHotels = response1?.hotels;
      const dataCars = response1?.cars;
      const dataFlights = response1?.flights;

      if (Array.isArray(dataHotels) && dataHotels.length > 0) {
        const htlCard = sanitizeHotelData(dataHotels ?? []);
        // orderObjectByDate(carCard, "desc");
        let tmp = temp.concat(htlCard);
        temp = tmp;
      }

      if (Array.isArray(dataCars) && dataCars.length > 0) {
        const carCard = sanitizCarData(dataCars ?? []);
        // orderObjectByDate(carCard, "desc");
        let tmp = temp.concat(filterRepeatItems(carCard, "car"));
        temp = tmp;
      }
      if (Array.isArray(dataFlights) && dataFlights.length > 0) {
        const flightCard = sanitizeFlightData(
          dataFlights ?? [],
          dateFormat || "YYYY/MM/DD"
        );
        const filteredArr = filterRepeatItems(flightCard, "flight");
        let tmp = temp.concat(filteredArr);
        temp = tmp;
      }

      orderObjectByDate(temp, "desc");

      if (temp.length == 0) {
        setdisabled(true);
      }
      setSanitizedData(temp);
    } else {
      const response = await getSuggestedAndStayData(langCode, section);

      if (response) {
        switch (type) {
          case "resentSearches":
            if (section === "hotel") {
              const response = await getRecentSearchByPage("HLT");
              console.log("recent searc hotel", response);
              if (response?.data) {
                const hotelCard = sanitizeHotelData(response?.data);
                orderObjectByDate(hotelCard, "asc");
                const filteredArr = filterRepeatItems(hotelCard, section);
                setSanitizedData(filteredArr);
              } else {
                setdisabled(true);
              }
              // old
              // const hotelCard = sanitizeHotelData(response[0]?.recent_search);
              // setSanitizedData(response?.hotels);
            }
            if (section === "car") {
              const response = await getRecentSearchByPage("CAR");
              if (response?.data) {
                const carCard = sanitizCarData(response?.data);
                orderObjectByDate(carCard, "asc");
                const filteredArr = filterRepeatItems(carCard, section);
                setSanitizedData(filteredArr);
              } else {
                setdisabled(true);
              }
              //old
              //const flighCard = sanitizCarData(response[0]?.recent_search);
              // setSanitizedData(flighCard);
            }

            if (section === "flight") {
              const response = await getRecentSearchByPage("FLT");
              if (response?.data) {
                const flightCard = sanitizeFlightData(
                  response?.data,
                  dateFormat || "YYYY/MM/DD"
                );
                orderObjectByDate(flightCard, "desc");
                const filteredArr = filterRepeatItems(flightCard, section);
                setSanitizedData(filteredArr);
              } else {
                setdisabled(true);
              }
            }

            break;
          case "suggested":
            sanitizeDataCard(response[2]?.suggested_destination);
            break;
          case "stays":
            sanitizeDataCard(response[2]?.world_destination);
            break;
        }
      }
    }
    setIsloading(false);
    setIsTriggeredApi(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isLogged) {
      setIsloading(true);
      console.log("ReplicateApi", isLogged, isTriggeredApi);
      if (isTriggeredApi && sanitizedData.length == 0) {
        let runOnMobile = size.width >= 992; // true is not mobile

        if (size.width == 0) {
          let isIos = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
          if (isIos) {
            if (typeof window !== "undefined") {
              let iosSize = window.innerWidth;
              runOnMobile = iosSize >= 992;
            }
          } else {
            if (typeof window !== "undefined") {
              let iosSize = window.innerWidth;
              runOnMobile = iosSize >= 992;
            }
          }
        }

        if (showRecentSearchesMobile) {
          // alert("showRecentSearcchesOnMovile");
          runOnMobile = true;
        }

        if (runOnMobile) {
          getDataApi();
        }
      }
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, isTriggeredApi]);

  useEffect(() => {
    setTimeout(() => {
      setdisabled(true);
    }, 500);
    return () => {};
  }, [sanitizedData]);

  return (
    <div>
      {sanitizedData.length >= 3 ? (
        <MainBody className="main-body-recent-search">
          {title && <MainH2 className="MainTitleAds">{title}</MainH2>}
          {subtitle && !breakpoints.down("sm") && (
            <MainP className="MainSubtitleAds">{subtitle}</MainP>
          )}
          <Spacer />
          <CardViewer
            data={sanitizedData}
            isMobile={breakpoints.down("sm")}
            isLoading={isloading}
          />
        </MainBody>
      ) : (
        !disabled && (
          <SkeletonMainBody style={{ display: "none" }}>
            <CustomCarousel
              breakPoints={cardBreakpoints}
              itemsToShow={1}
              isRTL={false}
              showArrows={false}
              // renderArrow={myArrow}
              pagination={false}
              disableArrowsOnEnd={true}
              itemPadding={[0, 0, 0, 0]}
              outerSpacing={0}
              theme={{ isMobilePage: breakpoints.down("md") ? true : false }}
            >
              {[1, 2, 3, 4].map((s) => {
                return (
                  <SkeletonCol key={`skeletonCol-${s}`}>
                    <SkeletonCard key={`SkeletonMainCard-carousel-${s}`} />
                  </SkeletonCol>
                );
              })}
            </CustomCarousel>
          </SkeletonMainBody>
        )
      )}
    </div>
  );
};
