import React from "react";
import {
  AdMainContainer,
  CardCol,
  CardRow,
  SectionTitle,
  SectionSubtitle,
  TitleRow,
} from "./styled";
import { AdImageTextCard } from "../AdImageTextCard";
import { useAdsToShow } from "@hooks/useAds";
import {
  checkImagePath,
  getRedirectUrl,
  getTextStringOrByLang,
} from "@helpers/helperAds";

type AdImageTextType = {
  section: string;
  space: string;
  config?: ConfigAdsType;
  title?: string;
  subtitle?: string;
};

export const AdImageTextViwer: React.FC<AdImageTextType> = ({
  section,
  space,
  title,
  subtitle,
}) => {
  const { data, isLoading } = useAdsToShow(section, space);

  if (!isLoading && data.length == 0) {
    return <></>;
  }
  if (isLoading && data.length == 0) {
    return <></>;
  }

  return (
    <AdMainContainer fluid>
      {console.log("data--url", data)}
      <TitleRow>
        {!!title && <SectionTitle xs={12}>{title}</SectionTitle>}
        {!!subtitle && <SectionSubtitle xs={12}>{subtitle} </SectionSubtitle>}
      </TitleRow>
      <CardRow className="justify-content-center">
        {data.map((ad, idx) => {
          return (
            <CardCol key={`ctc-ad-card-${idx}`} xs={12} md={6} lg={3}>
              <AdImageTextCard
                type={ad?.type}
                img={checkImagePath(String(ad?.image))}
                title={getTextStringOrByLang(ad.title)}
                subtitle={getTextStringOrByLang(ad.subtitle ?? ad.description)}
                url={String(getRedirectUrl(ad?.url))}
              />
            </CardCol>
          );
        })}
      </CardRow>
    </AdMainContainer>
  );
};
