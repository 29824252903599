import { Col } from "react-bootstrap";
import styled from "styled-components";
import Image from "next/image";
export const CardContainer = styled.div`
  /* width: 21rem; */
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;
export const ImageContainer = styled(Col)`
  position: relative;
  padding: 0;
  margin: 0;
  text-align: center;

  cursor: pointer;
`;

export const CardImg = styled(Image)``;

export const Title = styled.p`
  text-align: center;

  position: absolute;
  bottom: 0rem;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 700;
  width: 100%;

  @media only screen and (max-width: 768px) {
    bottom: 0rem;
  }

  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    font-size: 1.2rem;
  }
`;
export const SubtitleContainer = styled(Col)``;

export const Subtitle = styled.p`
  /* padding: 1.3rem; */
  text-align: justify;
  font-weight: 500;
  @media only screen and (max-width: 768px) {
  }
`;
