import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

export const AdMainContainer = styled(Container)`
  background-color: #fff;
  margin-top: 2rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
`;
export const TitleRow = styled(Row)``;

export const CardRow = styled(Row)`
  margin-top: 1rem;
`;
export const CardCol = styled(Col)``;

export const SectionTitle = styled(Col)`
  font-weight: 600;
  color: #123e78 !important;
  text-align: center;
  font-size: ${({ theme }: any) => (theme.isMobile ? "18px" : "26px")};
  line-height: 1.6;
`;

export const SectionSubtitle = styled(Col)`
  margin: 0;
  text-align: center;
  font-size: 21px;
  font-weight: 500;
  color: #666;
  display: block;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
`;
