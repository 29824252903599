import { useState } from "react";
import {
  CardContainer,
  CardImg,
  ImageContainer,
  Subtitle,
  SubtitleContainer,
  Title,
} from "./styles";
import {
  MATCH_URL_SPECIAL_PARAMS_WITH_ANGLE,
  defaultImgSelector,
} from "@helpers/helperAds";
import { useRouter } from "next/router";
import { useAuth } from "@contexts/AuthContext";
import { useDinamicUrl } from "@hooks/useAds";

type AdImageTextCardType = {
  type: string;
  img: string;
  title: string;
  subtitle: string;
  url: string;
};

export const AdImageTextCard: React.FC<AdImageTextCardType> = ({
  type,
  img,
  title,
  subtitle,
  url,
}) => {
  const router = useRouter();
  const [errorImage, setErrorImage] = useState(false);
  const { isLogged, currentUser } = useAuth();
  let { getUserInfoByKey } = useDinamicUrl();

  const urlAction = () => {
    if (url !== "") {
      console.log("url", url);

      const isFilledUrl = url.match(MATCH_URL_SPECIAL_PARAMS_WITH_ANGLE);
      if (isLogged && currentUser) {
        if (Array.isArray(isFilledUrl) && isFilledUrl.length > 0) {
          let tmpUrl = getUserInfoByKey(url, isFilledUrl, currentUser);
          if (tmpUrl) {
            url = tmpUrl;
          }
        }
      }

      let firstLetters = url.substring(0, 3);
      if (firstLetters.includes("htt") || firstLetters.includes("ww")) {
        if (firstLetters.includes("htt")) {
          window.location.replace(`${url}`);
        } else if (firstLetters.includes("ww")) {
          window.location.replace(`https://${url}`);
        }
      } else {
        if (router.pathname === "/search-detail") {
          router
            .replace({
              pathname: `/search-detail`,
              query: `${url}`,
            })
            .then(() => router.reload());
        } else {
          router.push({
            pathname: `/search-detail`,
            query: `${url}`,
          });
        }
      }
    }
  };

  return (
    <CardContainer>
      <ImageContainer
        onClick={() => {
          urlAction();
        }}
      >
        <CardImg
          width={"400px"}
          height={"400px"}
          src={!errorImage ? img : defaultImgSelector(type)}
          alt="card-main-img"
          onError={() => {
            setErrorImage(true);
            console.log("onerror");
          }}
          objectFit="fill"
          loading="lazy"
          placeholder="blur"
          blurDataURL="/images/modalSignInSignUp/photoNotFound.png"
        />
        <Title>{title}</Title>
      </ImageContainer>
      <SubtitleContainer
        xs={12}
        sm={{ offset: 2, span: 8 }}
        md={{ offset: 0, span: 11 }}
        lg={12}
      >
        <Subtitle>{subtitle}</Subtitle>
      </SubtitleContainer>
    </CardContainer>
  );
};
