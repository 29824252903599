import { Container, Col, Button } from "react-bootstrap";
import styled from "styled-components";
import Carousel from "react-elastic-carousel";

export const MainBody = styled(Container)`
  background-color: #edf2f6;
  padding-top: 2rem; //recent searches
  margin-bottom: 1rem;
`;
export const SkeletonMainBody = styled(Container)`
  background-color: #edf2f6;
  margin-top: 3rem;
`;

export const MainDiv = styled.div`
  padding-top: 4.5rem;
`;

export const MainH2 = styled.h2`
  font-weight: 600;
  color: var(--bs-body-color);
  text-align: center;
  font-size: ${({ theme }: any) => (theme.isMobile ? "18px" : "26px")};
  line-height: 1.6;
`;

export const MainP = styled.p`
  margin: 0;
  text-align: center;
  font-size: 21px;
  font-weight: 500;
  color: var(--bs-body-color);
  display: block;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
`;

export const ManDivCard = styled.div`
  margin-top: 1rem;
`;

export const Spacer = styled.div`
  margin-top: 2rem;
`;

export const SkeletonCol = styled(Col)`
  margin-top: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
`;

export const BtnArrow = styled(Button)`
  margin: -1.2rem;
  position: ${(props) => (props.theme.isMobilePage ? "absolute" : "static")};
  border-color: none !important;
  background-color: none !important;
  background: none !important;
  border: none;
  height: 100%;
  box-shadow: 0 0 0 0.25rem transparent !important;
  /* margin: auto; */
  margin-top: -6rem;
  margin-left: ${(props) => (props.theme.isRight ? ` 1rem` : " -0.1rem")};

  z-index: 1;
  ${(props) => (props.theme.isRight ? `right: -.5rem` : "left: -.5rem")};
  visibility: ${(props) => (props.theme.isEdge ? "hidden" : "visible")};
`;

export const Arrow = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  content: url(/images/general/carousel-left-arrow.svg);
  transform: ${(props) => (props.theme.isRight ? `` : "rotate(180deg)")};
`;
export const CustomCarousel = styled(Carousel)`
  position: ${(props) => (props.theme.isMobilePage ? "relative" : "static")};
`;
