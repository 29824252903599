import axios from "axios";
// import { getCookie, checkCookies } from "cookies-next";

export const getRecentSearchByPage = async (
  product_type: string
): Promise<RecentSearchResponseType | null> => {
  try {
    const response = await axios.post(`/api/general/getRecentSearch`, {
      product_type,
    });
    return response?.data;
  } catch (error) {
    console.info(error);
    return null;
  }
};
